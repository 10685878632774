<template>
  <div class="clarification__container">
    <modal-header-with-previous
      class="clarification__modal-header modal-header-with-previous"
      current-modal-name="clarification-modal"
      previous-modal-name="photo-analysis-result-update-modal"
      is-custom-close-handler
      @close-modal="beforeCloseModal"
    >
      <span slot="header-title" class="header-title">
        {{ $t('dashboard.label.clarificationQuestions') }}
      </span>
    </modal-header-with-previous>
    <main ref="clarification" class="clarification flex-column--align-center">
      <section class="clarification__questions ie-text-modal">
        <clarification-question
          v-for="(question, index) in questions"
          :key="question.translationLabel"
          v-model="question.answer"
          :question="question"
          is-question-key
          @input="scrollToNextQuestion(index)"
        />
      </section>
    </main>
    <footer class="clarification__footer__container">
      <div class="divider otherXS"></div>
      <section class="clarification__footer flex--center--center">
        <button class="rounded-button-white" @click="seeRecommendedTreatment">
          {{ $t('dashboard.photoAnalysis.action.seeRecommendedTreatment') }}
        </button>
      </section>
    </footer>

    <quit-photo-analysis-warning-modal class="onlyXS" return-to-opened-patient />
  </div>
</template>

<script>
import { first, get } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import PerfectScrollbar from 'perfect-scrollbar';

import DashboardQuestion from '@/modules/dashboard/components/dashboard/create-treatment/common/DashboardQuestion';
import ModalHeaderWithPrevious from '@/modules/dashboard/components/dashboard/common/ModalHeaderWithPrevious';
import ClarificationQuestion from '@/modules/dashboard/components/dashboard/create-treatment/common/ClarificationQuestion';
import QuitPhotoAnalysisWarningModal from '@/modules/dashboard/components/dashboard/create-treatment/photo-analysis/QuitPhotoAnalysisWarningModal';

import { sendFinalTreatmentResults, getClarificationQuestions } from '@/modules/dashboard/api';

import {
  getClarificationQuestionKey,
  getClarificationQuestionsResponses,
  getPatientPageNameForTreatmentRecommendationProcess,
  isMobileDevice
} from '@/modules/dashboard/utils';

import rootTypes from '@/store/types';
import { types as dashTypes } from '@/modules/dashboard/store/types';
import { CREATE_TREATMENT_TYPES, PATIENT_TAB_NAMES } from '@/modules/dashboard/api/constants';

const DEFAULT_QUESTIONS = {
  q1: new DashboardQuestion({
    translationLabel: 'isPregnant',
    question: 'dashboard.label.patientPregnant'
  }),
  q2: new DashboardQuestion({
    translationLabel: 'hasUVExposure',
    question: 'dashboard.label.UVexposure'
  }),
  q3: new DashboardQuestion({
    translationLabel: 'hasColdSores',
    question: 'dashboard.label.haveColdSores'
  }),
  q4: new DashboardQuestion({
    translationLabel: 'hasAspirinAllergies',
    question: 'dashboard.label.allergicToAspirin'
  }),
  q5: new DashboardQuestion({
    translationLabel: 'doesToleratesExfoliants',
    question: 'dashboard.label.tolerateExfoliants'
  }),
  q6: new DashboardQuestion({
    translationLabel: 'sufferAcneRosacea',
    question: 'dashboard.photoAnalysis.label.treatedFromAcne'
  }),
  q7: new DashboardQuestion({
    translationLabel: 'hasSoyAllergies',
    question: 'dashboard.label.allergicToSoy'
  }),
  q8: new DashboardQuestion({
    translationLabel: 'treatedFromCancer',
    question: 'dashboard.photoAnalysis.label.treatedForCancer'
  })
};

const PATIENT_CARD_ROUTE_NAME = 'PatientCard';

const BOTTOM_SCROLL_OFFSET = 500;

export default {
  name: 'Clarification',
  components: { QuitPhotoAnalysisWarningModal, ClarificationQuestion, ModalHeaderWithPrevious },
  data() {
    return {
      scrollbar: null,
      questionNumbersToAsk: [],
      questions: []
    };
  },
  computed: {
    ...mapGetters({
      currentPatientId: dashTypes.getters.CURRENT_PATIENT_ID,
      photoAnalysisPhotoId: dashTypes.getters.PHOTO_ANALYSIS_PHOTO_ID,
      photoAnalysisResult: dashTypes.getters.PHOTO_ANALYSIS_RESULT,
      photoAnalysisUpdatedResults: dashTypes.getters.PHOTO_ANALYSIS_UPDATED_RESULTS,
      doctorCountry: rootTypes.getters.GET_USER_COUNTRY,
      doctorLocale: rootTypes.getters.GET_LOCALE,
      diagnostics: dashTypes.getters.CURRENT_PATIENT_DIAGNOSTICS
    })
  },
  async mounted() {
    this.scrollbar = new PerfectScrollbar(this.$refs.clarification, {
      suppressScrollX: true,
      wheelPropagation: false
    });

    this.questionNumbersToAsk = await this.getQuestionNumbersToAsk();
    this.questions = this.questionNumbersToAsk.map(number => ({
      ...DEFAULT_QUESTIONS[getClarificationQuestionKey(number)]
    }));
  },
  methods: {
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING,
      resetPhotoAnalysis: dashTypes.actions.RESET_PHOTO_ANALYSIS,
      setActivePatientTab: dashTypes.actions.SET_ACTIVE_PATIENT_TAB,
      loadPatient: dashTypes.actions.LOAD_PATIENT,
      selectCreateTreatmentType: dashTypes.actions.SELECT_CREATE_TREATMENT_TYPE,
      selectRecommendationDiagnostic: dashTypes.actions.SELECT_RECOMMENDATION_DIAGNOSTIC
    }),
    beforeCloseModal() {
      this.$modal.show('quit-photo-analysis-warning-modal', {
        noAnswerHandler: () => {
          this.$modal.show('clarification-modal');
        }
      });

      this.$modal.hide('clarification-modal');
    },
    async getQuestionNumbersToAsk() {
      // const modifiedDiagnostic = this.getModifiedDiagnostic();
      const modifiedDiagnostic = get(this.photoAnalysisResult, 'diagnosticv2', null);


      const {
        data: {
          clarificationQuestions: { questionsToAsk }
        }
      } = await getClarificationQuestions(this.currentPatientId, {
        treatmentProposal: this.photoAnalysisResult,
        reviewedDiagnostic: {
          clarificationQuestions: null,
          modifiedDiagnostic,
          doctorsCountry: this.doctorCountry,
          doctorsLanguage: this.doctorLocale
        }
      });

      return questionsToAsk;
    },
    async seeRecommendedTreatment() {
      if (await this.$validator.validateAll()) {
        const isMobile = isMobileDevice();
        const results = await this.getFinalTreatmentResults();

        this.setLoading(true);

        await sendFinalTreatmentResults(this.currentPatientId, this.photoAnalysisPhotoId, results);
        await this.loadPatient(this.currentPatientId);

        if (isMobileDevice()) {
          await this.selectRecommendationDiagnostic(first(this.diagnostics));
        } else {
          await this.selectCreateTreatmentType({
            createTreatmentType: CREATE_TREATMENT_TYPES.DIAGNOSTIC_BASED_TREATMENT,
            recommendationDiagnostic: first(this.diagnostics)
          });
        }

        await this.setActivePatientTab(PATIENT_TAB_NAMES.CREATE_TREATMENT);

        this.setLoading(false);

        if (this.$route.name === PATIENT_CARD_ROUTE_NAME && !isMobile) {
          this.$modal.hide('clarification-modal');

          return;
        }

        const nextRouteName = getPatientPageNameForTreatmentRecommendationProcess();

        if (this.$route.name !== nextRouteName) {
          this.$router.push({
            name: getPatientPageNameForTreatmentRecommendationProcess(),
            params: { id: this.currentPatientId, showTreatmentRecommendations: true }
          });
        }

        this.resetPhotoAnalysis();
      }
    },
    getModifiedDiagnostic() {
      const { ageGroup } = this.photoAnalysisUpdatedResults;
      const updatedAgeGroup = Number.isInteger(ageGroup) ? ageGroup : -1;

      return {
        ...this.photoAnalysisUpdatedResults,
        ageGroup: updatedAgeGroup
      };
    },
    async getFinalTreatmentResults() {
      const questionsResponse = getClarificationQuestionsResponses(
        this.questionNumbersToAsk,
        this.questions
      );

      const modifiedDiagnostic = this.getModifiedDiagnostic();

      return {
        treatmentProposal: this.photoAnalysisResult,
        reviewedDiagnostic: {
          clarificationQuestions: {
            responses: questionsResponse
          },
          modifiedDiagnostic,
          doctorsCountry: this.doctorCountry,
          doctorsLanguage: this.doctorLocale
        }
      };
    },
    scrollToNextQuestion(index) {
      const shouldScroll = index > 2;

      if (!shouldScroll) {
        return;
      }

      if (isMobileDevice()) {
        this.$scrollTo('.clarification', { offset: BOTTOM_SCROLL_OFFSET });

        return;
      }

      this.$refs.clarification.scrollTop = BOTTOM_SCROLL_OFFSET;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../../assets/css/common/icons.css';
@import '../../../../../../assets/scss/common/text';
@import '../../../../../../assets/scss/common/buttons';
@import '../../../../../../assets/scss/modals/modal-header-with-previous-shared';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

.clarification {
  height: 550px;
  position: relative;
  padding: 0 30px;

  &__container {
    height: 100%;
    position: relative;
  }

  &__footer {
    height: 70px;

    &__container {
      position: absolute;
      bottom: 0;
    }
  }
}

.header-title {
  font-size: 15px;
  line-height: 18px;

  &__step {
    font-weight: 600;
    color: $grey-color;
  }
}

@media (min-width: 768px) and (max-height: 700px) {
  .clarification {
    height: 350px;
  }
}

@media (max-width: 767px) {
  .clarification {
    height: auto;
    padding: 85px 30px 70px;

    &__container {
      min-height: 100vh;
      background-color: white;
    }

    &__modal-header {
      position: fixed;
      top: 0;
      z-index: 1;
      background-color: $background;
    }

    &__footer {
      height: 60px;

      &__container {
        position: fixed;
        background: $white;
        box-shadow: 0 -6px 7px rgba(148, 166, 176, 0.15);
      }
    }
  }

  .header-title {
    font-size: 13px;
    line-height: 15px;
    color: $main-title-color;

    &__step {
      font-weight: normal;
      color: $main-title-color;
    }
  }
}
</style>
