<template>
  <div class="select-container">
    <vue-select
      v-bind="$attrs"
      :options="selectOptions"
      :searchable="false"
      :show-labels="false"
      @input="onSelect">
      <span slot="noOptions">{{ $t('dashboard.label.listEmpty') }}</span>
  </vue-select>
  </div>
</template>

<script>
// import Multiselect from 'vue-multiselect';

// import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'vue-select/dist/vue-select.css';

import VueSelect from 'vue-select';


export default {
  name: 'VSelect',
  components: {
    VueSelect
  },
  inheritAttrs: false,
  props: {
    options: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    selectOptions() {
      return this.options.map(option => ({ ...option, label: this.$t(option.label) }));
    }
  },
  methods: {
    onSelect(value) {
      this.$emit('input', value);
    }
  }
};
</script>


<style lang="scss">
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/sass/mixins';

style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #ffffff;
  border: none;
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.006em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  }
.style-chooser .vs__dropdown-option {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.006em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  white-space: normal;
}
.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  background-color: #ffffff;
}

.select-container {
  &.custom--with-border {
    .multiselect {
      height: 38px;

      &__tags {
        padding: 8px 40px 8px 10px;
        height: 100%;

        border: 1px solid $light-blue;
        background-color: $white;
      }

      &__placeholder {
        margin: 0;
        padding: 0;
      }

      &__content-wrapper {
        max-width: 100%;
        overflow: hidden;
      }

      &__option {
        white-space: normal;
        word-break: break-word;
      }
    }
  }

  &.custom--error {
    .multiselect {
      &__tags {
        border: 1px solid $error-color;
      }
    }
  }

  .multiselect {
    &--disabled {
      border-radius: 5px;

      .multiselect {
        &__tags {
          background-color: $background;
        }

        &__select {
          background-color: transparent;
        }
      }
    }

    &__select {
      width: 100%;
      height: 100%;
      z-index: 1;

      &::before {
        @include absolute-center;

        width: 9px;
        height: 6px;
        margin-top: 0;
        border: none;
        background-image: url('~@/assets/images/dashboard/icons/dropdown-arrow-down.svg');
        background-position: center center;
        background-repeat: no-repeat;
      }
    }

    &__content {
      padding: 0 10px;
    }

    &__tags {
      display: flex;
      box-sizing: border-box;
      position: relative;
      border: 1px solid transparent;
      background-color: transparent;
      cursor: pointer;

      &:hover {
        background-color: $white;
        border-radius: 4px;
      }
    }

    &__single {
      margin: 0;
      background-color: transparent;
      font-size: 15px;
      line-height: 150%;
      color: $main-title-color;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left;
    }

    &__placeholder {
      line-height: 150%;
      margin: 0;
      padding: 0;
    }

    &__tag {
      background-color: transparent;
      border: 1px solid $dim-white;
      border-radius: 15px;
      color: $black-color-text;
    }

    &__tag-icon {
      &::after {
        @include absolute-center;

        content: '';
        width: 9px;
        height: 9px;
        background-image: url('~@/assets/images/dashboard/icons/close-icon.svg');
        background-size: cover;
      }
      &:focus,
      &:hover {
        background-color: transparent;
      }
    }

    &__content-wrapper {
      right: 0;
      width: auto;
      margin: 3px 0;
      border-radius: 8px;
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);

      overflow: hidden;
    }

    &__option {
      font-size: 15px;
      line-height: 17px;
      font-weight: lighter;
      color: $dim-grey-color;
    }

    &__option--highlight {
      background-color: $white;
      color: $grey-color;

      &:hover {
        color: $main-title-color;
      }
    }

    &__option--selected {
      position: relative;
      font-weight: normal;
      background-color: $white;
      color: $black-color-text;
    }

    &__option--group {
      font-weight: 700;
      color: $option-group-gray-color;
      background-color: $white;
    }

    &__option--disabled {
      padding: 20px 0 8px;
      font-weight: 100;
      color: $silver-color !important;
      background-color: transparent !important;
      border-bottom: 1px solid $dim-white;
    }

    &--active {
      .multiselect__tags {
        background-color: $white;
        border-radius: 4px;
        border: 1px solid $light-blue;
      }

      .multiselect__single {
        background-color: $white;
      }
    }
  }
}

.field-container_allergies {
  .select-container {
    .multiselect {
      min-height: 43px;

      &__select {
        min-height: 41px;
      }

      &__content-wrapper {
        left: 0;
        max-width: 300px;
      }

      &__content {
        padding: 0 20px;
      }

      &__placeholder {
        margin: 0;
        padding: 0;
      }

      &__option {
        padding-right: 40px;
        white-space: normal;
      }

      &__tags-wrap {
        &::after {
          content: 'Add an allergy';
          display: none;
          padding: 4px 26px 6px 10px;
          margin-right: 10px;
          margin-bottom: 5px;
          font-size: 12px;
          font-weight: 200;
          font-style: italic;
          color: $grey-color;
          line-height: 100%;
          overflow: hidden;
        }
      }

      &__tags {
        min-height: 43px;

        &:focus {
          border: 1px solid $light-blue;
        }

        &:hover {
          .multiselect {
            &__tags-wrap {
              &::after {
                margin-top: -3px;

                content: 'Add an allergy';
                display: inline-block;
              }
            }
          }
        }
      }

      &__option--selected {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 10px;
          width: 17px;
          height: 12px;
          background-image: url('~@/assets/images/dashboard/icons/check.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
      }

      &__option--disabled {
        color: $grey-color !important;
      }

      &--active {
        .multiselect {
          &__tags-wrap {
            &::after {
              content: 'Add an allergy';
              display: inline-block;
            }
          }
        }
      }
    }
  }
}

.participants-select {
  .select-container {
    .multiselect__content-wrapper {
      max-height: 240px !important;
    }
  }
}

.send-offer-section__content .participants-select {
  .select-container {
    .multiselect__content-wrapper {
      max-height: 230px !important;
    }
  }
}

@media (max-width: 767px) {
  .select-container {
    .multiselect {
      &__tags {
        background-color: $white;
        border-radius: 4px;
        font-size: 13px;
      }

      &__single {
        margin: 0;
        padding: 0;
        background-color: $white;
        font-size: 13px;
        line-height: 170%;
      }

      &__placeholder {
        margin: 0;
        padding: 0;
        line-height: 170%;
      }

      &__option {
        min-height: 30px;
        white-space: normal;
        font-size: 13px;
        line-height: 15px;

        &--disabled {
          font-size: 12px;
          line-height: 18px;
          padding-bottom: 2px;
        }
      }

      &__content-wrapper {
        overflow: auto;
      }

      &--disabled {
        .multiselect__single {
          background-color: transparent;
        }
      }
    }

    &.custom--with-border {
      .multiselect {
        &__placeholder {
          margin: 0;
          padding: 0;
        }
      }
    }
  }

  .field-container_allergies {
    .select-container {
      .multiselect__content-wrapper {
        left: unset;
        width: 300px;
        height: 400px;
        max-height: none !important;
        text-align: left;
      }
    }
  }
}
</style>
